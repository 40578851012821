import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ContactPage = props => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t(`contact_us`)} lang={props.language} />
      <section className="section has-bg-craft" id="">
        <div className="container">
          <div className="box content">
            <h1>{t(`contact_us`)}</h1>
            <div className="columns">
              <div className="column is-6-desktop">
                <form
                  name="contact"
                  netlify
                  netlify-honeypot="bot-field"
                  hidden
                >
                  <input type="text" name="name" />
                  <input type="email" name="email" />
                  <textarea name="message"></textarea>
                </form>
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />

                  <div className="field required">
                    <label className="label">{t(`CONTACT.NAME`)}</label>
                    <div className="control">
                      <input
                        className="input"
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>

                  <div className="field required">
                    <label className="label">{t(`CONTACT.EMAIL`)}</label>
                    <div className="control">
                      <input
                        className="input"
                        name="email"
                        type="email"
                        placeholder="hello@"
                        required
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">
                      {t(`CONTACT.RESTAURANT_NAME`)}
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        name="restaurant_name"
                        type="text"
                        placeholder="Restaurant Name"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">{t(`CONTACT.MESSAGE`)}</label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        name="message"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="field">
                    <div className="control">
                      <button
                        className="button is-primary is-fullwidth"
                        type="submit"
                      >
                        {t(`CONTACT.SUBMIT`)}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="column is-6-desktop">
                <p>
                  {t(`CONTACT.DROP_EMAIL_US`)}:{" "}
                  <a href="mailto:info@uketoly.com">info@uketoly.com</a>
                  <br />
                  {t(`CONTACT.WE_WILL_GET_IN_TOUCH_WITH_YOU_SOON`)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
